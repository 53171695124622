import {
    CustomCodeAllRequest,
    CustomCodeCreateRequest,
    CustomCodeDeleteRequest,
    ECustomCodeType,
    CustomCodeAllResponse,
} from '@gamebase-web-ops/code';
import { COMMON_PROJECT_ID } from '../../constants/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CodeRepository } from 'repository';
import { notificationPush } from 'utils/notificationUtils';

interface MenuList {
    data: CustomCodeAllResponse<{ key: string; desc: string }> | undefined;
}
export default function useProjectMenu(projectId: string) {
    const queryClient = useQueryClient();

    const { data: allMenuList } = useQuery('useProjectMenuAll', () =>
        CodeRepository.allCustomCodes(
            new CustomCodeAllRequest({
                projectId: COMMON_PROJECT_ID,
                codeType: ECustomCodeType.PROJECT_MENU,
            }),
        ),
    );

    const { data: menuList }: MenuList = useQuery(['project', projectId], () =>
        CodeRepository.allCustomCodes(
            new CustomCodeAllRequest({
                projectId,
                codeType: ECustomCodeType.PROJECT_MENU,
            }),
        ),
    );

    const createProjectMenuMutation = useMutation(
        async (payload: CustomCodeCreateRequest) => {
            await CodeRepository.createCustomCode(new CustomCodeCreateRequest(payload));
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['project', projectId]);
                notificationPush({ status: 'success', message: '해당 메뉴를 추가했습니다.' });
            },
        },
    );

    const deleteProjecteMenuMutation = useMutation(
        async (payload: CustomCodeDeleteRequest) => {
            await CodeRepository.deleteCustomCode(new CustomCodeDeleteRequest(payload));
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['project', projectId]);
                notificationPush({ status: 'success', message: '해당 메뉴를 삭제했습니다.' });
            },
        },
    );

    const projectAllMenuList = allMenuList ? allMenuList.result : [];
    const projectMenuList = menuList ? menuList.result : [];

    const { mutate: deleteProjectMenu } = deleteProjecteMenuMutation;
    const { mutate: createProjectMenu } = createProjectMenuMutation;

    return { projectAllMenuList, projectMenuList, deleteProjectMenu, createProjectMenu };
}
