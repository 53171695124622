import { useCallback } from 'react';
import { BlockUserRepository } from 'repository';
import useBlockUserModel from './model/useBlockUser.model';
import { EBanRequestState } from '@gamebase-ops/interfaces/consts';

export default function useBlockUser(projectId: string, limit = 1, banState?: EBanRequestState) {
    const {
        setListQuery: setCurrentQuery,
        data: blockUserResponse,
        listQuery: currentQuery,
        reBanUser,
        isReBan,
        refetch,
    } = useBlockUserModel({ projectId, limit, banState });

    const uploadFile = useCallback(
        async (param: FormData) => {
            await BlockUserRepository.upload({ query: { projectId }, body: param });
        },
        [projectId],
    );

    const setPage = useCallback(
        (page: number) => {
            setCurrentQuery((prev) => {
                if (prev) {
                    return {
                        ...prev,
                        page,
                    };
                } else {
                    throw new Error('query의 내용이 채워져야 합니다.');
                }
            });
        },
        [setCurrentQuery],
    );

    const setQueryUserId = useCallback(
        ({ accountId, guid }: { accountId?: string; guid?: string }, limit: number) => {
            setCurrentQuery((prev) => {
                if (prev)
                    return {
                        ...prev,
                        accountId: accountId || undefined,
                        guid: guid || undefined,
                        limit,
                    };
                return {
                    accountId: accountId || undefined,
                    guid: guid || undefined,
                    limit,
                    page: 1,
                    projectId,
                };
            });
        },
        [projectId, setCurrentQuery],
    );

    const setQueryPage = useCallback(
        (param: { page: number; limit: number }) => {
            setCurrentQuery({
                ...param,
            });
        },
        [setCurrentQuery],
    );

    const setQueryState = useCallback(
        (state?: EBanRequestState) => {
            setCurrentQuery((prev) => {
                if (prev) {
                    return {
                        ...prev,
                        state,
                    };
                } else {
                    throw new Error('query의 내용이 채워져야 합니다.');
                }
            });
        },
        [setCurrentQuery],
    );

    return {
        uploadFile,
        blockUsers: blockUserResponse?.result.data || [],
        totalCount: blockUserResponse?.result.totalCount || 0,
        currentQuery,
        reBanUser,
        isReBan,
        setPage,
        setQueryUserId,
        setQueryPage,
        setQueryState,
        requestBlockUser: setCurrentQuery,
        refetch,
    };
}
