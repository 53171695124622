import { useCallback, useState } from 'react';
import { VoidedPurchasesRepository } from 'repository';
import { RepayRequest, VoidedPurchaseLogListRequest } from '@gamebase-web-ops/voidedpurchase-log';
import { useMutation, useQuery } from 'react-query';
import QueryString from 'qs';
import RequestAccess from 'utils/api/ReqeustAccess';
import { API_URL } from 'constants/constants';
import { notificationPush } from 'utils/notificationUtils';
import moment from 'moment';

export default function useVoidedPurchases(projectId: string) {
    const limit = 20;
    const [listQuery, setListQuery] = useState<VoidedPurchaseLogListRequest>();

    const { data, isLoading } = useQuery(['voidedPurchaseList', listQuery], () =>
        listQuery
            ? VoidedPurchasesRepository.list(
                  new VoidedPurchaseLogListRequest({
                      ...listQuery,
                      fromDt: listQuery.fromDt ? moment(listQuery.fromDt).startOf('date').toISOString() : undefined,
                      toDt: listQuery.toDt ? moment(listQuery.toDt).endOf('date').toISOString() : undefined,
                  }),
              )
            : undefined,
    );

    const setQuery = useCallback(
        (param: Omit<VoidedPurchaseLogListRequest, 'projectId'>) => {
            setListQuery({
                ...param,
                projectId,
            });
        },
        [projectId],
    );

    const setPageNumber = useCallback((page: number) => {
        setListQuery((prev) => {
            if (prev) {
                return { ...prev, page };
            }
        });
    }, []);

    const downloadCsv = useCallback(() => {
        if (listQuery) {
            const { fromDt, toDt, userId, orderId, productId } = listQuery;
            const accessToken = RequestAccess.getInstance().getAccessToken();
            const queryString = QueryString.stringify(
                {
                    projectId,
                    fromDt,
                    toDt,
                    userId,
                    orderId,
                    productId,
                    token: accessToken,
                },
                { addQueryPrefix: true },
            );
            const a = document.createElement('a');
            a.href = `${API_URL}/api/v1/voidedpurchase-logs/download${queryString}`;
            a.click();
            a.remove();
        }
    }, [listQuery, projectId]);

    const { mutateAsync: repay, isLoading: repayLoading } = useMutation(
        async (payload: RepayRequest) => {
            await VoidedPurchasesRepository.repay(payload);
        },
        {
            onSuccess: () => {
                notificationPush({
                    status: 'success',
                    title: '재결제 요청을 성공하였습니다.',
                });
            },
        },
    );

    return {
        listQuery,
        setQuery,
        limit,
        isLoading,
        voidedPurchaseList: data?.result.data,
        download: () => {
            downloadCsv();
        },
        setPageNumber,
        totalCount: data?.result.totalCount,
        repay,
        repayLoading,
    };
}
