import { Form, Input, Tabs, Button, Select, InputNumber, Radio } from 'antd';
import { Common } from 'styles/common';
import { css } from '@emotion/react';
import DateTimePicker from 'components/utils/formItems/DateTimePicker';
import { useMemo, useState } from 'react';
import { dateTimeMerge } from 'utils/dateTime';
import { EnterIgnoreForm, OsIcon } from 'components';
import { useFormatMessage, useFormRules } from 'hooks';
import { EMERGENCY_NOTICE_PLATFORM } from 'constants/constants';
import { emptyDeleteKey } from 'utils/object';
import moment from 'moment';

interface IFirstStep {
    onSubmit?: (data: any) => void;
    publishData: ICreateNotice | null;
    type: string;
    languages?: string[];
}

interface LangItem {
    forceRender: boolean;
    key: string;
    children: JSX.Element;
    label: string;
}

const styleAndroid = {
    color: Common.colors.android,
    fontSize: Common.fontSize.android,
};
const styleIOS = {
    color: Common.colors.ios,
    fontSize: Common.fontSize.ios,
};
function FirstStep({ onSubmit, publishData, type: NoticeType, languages }: IFirstStep) {
    const [startTime, setStartTime] = useState(publishData?.startTime);
    const [endTime, setEndTime] = useState(publishData?.endTime);
    const { formValidator } = useFormRules();

    const { messageFormatting } = useFormatMessage();
    const onFinish = async (values: any) => {
        const startTime = dateTimeMerge(values.startTime.date._d, values.startTime.time._d);
        const endTime = dateTimeMerge(values.endTime.date._d, values.endTime.time._d);

        if (onSubmit) {
            onSubmit({
                ...values,
                ...{
                    messages: emptyDeleteKey(values.messages || {}),
                    startTime: startTime,
                    endTime: endTime,
                    titles: emptyDeleteKey(values.titles || {}),
                },
            });
        }
    };

    const publishDataMessages = useMemo(() => {
        return publishData?.messages;
    }, [publishData]);

    const publishDataTitles = useMemo(() => {
        return publishData?.titles;
    }, [publishData]);

    const langItems: LangItem[] | undefined = languages?.map((lang) => {
        return {
            forceRender: true,
            key: lang,
            label: lang,
            children: (
                <>
                    {NoticeType === 'normal' && (
                        <Form.Item
                            name={['titles', lang]}
                            initialValue={publishDataTitles && publishDataTitles[lang as keyof IMessage]}
                        >
                            <Input data-test_id={`title-${lang}`} />
                        </Form.Item>
                    )}
                    <Form.Item
                        name={['messages', lang]}
                        initialValue={publishDataMessages && publishDataMessages[lang as keyof IMessage]}
                    >
                        <Input.TextArea
                            data-test_id={`message-${lang}`}
                            showCount
                            maxLength={10000}
                            style={{ height: '40rem' }}
                            onKeyDown={(e) => {
                                if (e.key.toLowerCase() === 'enter') e.stopPropagation();
                            }}
                        />
                    </Form.Item>
                </>
            ),
        };
    });

    return (
        <EnterIgnoreForm name="control-hooks" onFinish={onFinish} layout="vertical">
            <Form.Item
                name="description"
                label={messageFormatting('TITLE')}
                rules={formValidator.getRules('notice_title')}
                initialValue={publishData?.description}
            >
                <Input />
            </Form.Item>
            {NoticeType === 'ingame' && (
                <Form.Item
                    name="versions"
                    label={messageFormatting('APP_VERSION')}
                    initialValue={publishData?.versions}
                >
                    <Select mode="tags" style={{ width: '100%' }} />
                </Form.Item>
            )}

            <Form.Item
                name="defaultLanguage"
                label={messageFormatting('NOTICE_FIRST_LANGUAGE')}
                initialValue={publishData?.defaultLanguage || ''}
            >
                <Select placeholder={messageFormatting('NOTICE_FIRST_LANGUAGE_MESSAGE')}>
                    <Select.Option key="" value="">
                        없음
                    </Select.Option>
                    {languages?.map((lang) => (
                        <Select.Option key={lang} value={lang}>
                            {lang}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label={messageFormatting('LANGUAGE')} initialValue={publishData?.messages}>
                <Tabs type="card" defaultActiveKey={publishData?.defaultLanguage} items={langItems}></Tabs>
            </Form.Item>
            {NoticeType === 'ingame' && (
                <Form.Item
                    label={messageFormatting('REPEAT_NUM')}
                    name="repeatNum"
                    initialValue={publishData?.repeatNum || 1}
                >
                    <InputNumber min={1} />
                </Form.Item>
            )}
            <section data-test_id="startDateTimePicker">
                <DateTimePicker
                    baseName="startTime"
                    title={messageFormatting('START_AT')}
                    layout="vertical"
                    defaultValue={publishData?.startTime}
                    onChange={(dateString) => setStartTime(dateString)}
                />
                {startTime && moment(new Date(startTime)).format('YYYY-MM-DD HH:mm:ss')}
            </section>
            <section data-test_id="endDateTimePicker">
                <DateTimePicker
                    baseName="endTime"
                    title={messageFormatting('END_AT')}
                    layout="vertical"
                    defaultValue={publishData?.endTime}
                    onChange={(dateString) => setEndTime(dateString)}
                />
                {endTime && moment(new Date(endTime)).format('YYYY-MM-DD HH:mm:ss')}
            </section>
            {NoticeType === 'emergency' && (
                <Form.Item
                    name="platform"
                    label="플랫폼"
                    style={{ marginTop: '24px' }}
                    initialValue={publishData?.platform || EMERGENCY_NOTICE_PLATFORM.BOTH}
                >
                    <Radio.Group>
                        <Radio value={EMERGENCY_NOTICE_PLATFORM.ANDROID} css={alignItemsCenter}>
                            <OsIcon os="Android" style={styleAndroid} />
                        </Radio>
                        <Radio value={EMERGENCY_NOTICE_PLATFORM.iOS} css={alignItemsCenter}>
                            <OsIcon os="iOS" style={styleIOS} />
                        </Radio>
                        <Radio value={EMERGENCY_NOTICE_PLATFORM.BOTH} css={alignItemsCenter}>
                            <OsIcon os="Android" style={styleAndroid} />
                            <OsIcon os="iOS" style={styleIOS} />
                        </Radio>
                    </Radio.Group>
                </Form.Item>
            )}
            <Form.Item style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit" data-test_id="toNextButton">
                    {messageFormatting('NEXT')}
                </Button>
            </Form.Item>
        </EnterIgnoreForm>
    );
}

export default FirstStep;

const alignItemsCenter = css`
    align-items: center;
`;
