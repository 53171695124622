import { css } from '@emotion/react';
import { CommunityCreateRequest } from '@gamebase-web-ops/community';
import { Button, Descriptions, Form, Input, InputNumber, Modal } from 'antd';
import EnterIgnoreForm from 'components/utils/EnterIgnoreForm';
import ImageInput from 'components/utils/ImageInput';
import { useCallback } from 'react';

interface CommunityEditorProps {
    isVisible: boolean;
    onCloseModal: () => void;
    onSubmit: (param: any) => void;
    onClickClear?: (param: any) => void;
    projectId: string;
    modifyData?: Omit<CommunityCreateRequest, 'projectId'>;
    isLoading?: boolean;
    language: string;
    uploadImage: any;
}
export default function CommunityEditor({
    isVisible,
    onCloseModal,
    onSubmit,
    projectId,
    modifyData,
    onClickClear,
    isLoading,
    language,
    uploadImage,
}: CommunityEditorProps) {
    if (!isVisible) {
        return <></>;
    }

    const imageChange = () => {};
    const submitListener = useCallback(
        async (data) => {
            const submitData = {
                projectId,
                language,
                name: data.name,
                url: data.url,
                seq: Number(data.seq),
                image: 'test',
            };
            if (data.file?.size) {
                submitData.image = await uploadImage(data.file);
            }

            onSubmit(submitData);
        },
        [onSubmit],
    );

    return (
        <Modal
            open={isVisible}
            onCancel={onCloseModal}
            title={modifyData ? '영상 수정' : '영상 등록'}
            footer={null}
            width="880px"
        >
            <EnterIgnoreForm onSubmit={submitListener}>
                <Descriptions column={1} bordered labelStyle={{ width: '200px' }}>
                    <Descriptions.Item label="언어">
                        <Form.Item name="language">
                            <Input name="language" disabled defaultValue={language} />
                        </Form.Item>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions column={1} bordered labelStyle={{ width: '200px' }}>
                    <Descriptions.Item label="제목">
                        <Form.Item name="name">
                            <Input name="name" placeholder="제목을 입력해주세요." defaultValue={modifyData?.name} />
                        </Form.Item>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions column={1} bordered labelStyle={{ width: '200px' }}>
                    <Descriptions.Item label="이미지">
                        <Form.Item name="image">
                            <ImageInput name="file" onFileChange={imageChange} />
                        </Form.Item>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions column={1} bordered labelStyle={{ width: '200px' }}>
                    <Descriptions.Item label="링크">
                        <Form.Item name="url">
                            <Input name="url" placeholder="링크를 등록해 주세요." defaultValue={modifyData?.image} />
                        </Form.Item>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions column={1} bordered labelStyle={{ width: '200px' }}>
                    <Descriptions.Item label="순서">
                        <Form.Item name="seq">
                            <InputNumber
                                name="seq"
                                css={numberInPut}
                                placeholder="정렬 순서를 입력해주세요."
                                defaultValue={modifyData?.seq}
                            />
                        </Form.Item>
                    </Descriptions.Item>
                </Descriptions>
                <footer css={formFooter}>
                    <Button loading={isLoading} htmlType="submit" type="primary">
                        저장
                    </Button>
                </footer>
            </EnterIgnoreForm>
        </Modal>
    );
}

const formFooter = css`
    margin-top: 24px;
    padding-top: 12px;
    display: flex;
    justify-content: right;
`;
const numberInPut = css`
    width: 100%;
`;
