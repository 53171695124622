import { ProjectDetailDto } from '@gamebase-web-ops/project';
import { Transfer } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { ECustomCodeType } from '@gamebase-web-ops/code';
import useProjectMenu from 'hooks/admin/useProjectMenu';

interface IProp {
    project: ProjectDetailDto;
}

export default function ProjectMenuEdit({ project }: IProp) {
    const projectId = project.id;
    const { projectAllMenuList, projectMenuList, createProjectMenu, deleteProjectMenu } = useProjectMenu(projectId);
    const [sourceSelectedKey, setSourceSelectedKey] = useState<string>('');
    const [targetSelectedKey, setTargetSelectedKey] = useState<string>('');
    const dataSource = useMemo(() => {
        return projectAllMenuList.map(({ codeValue }: any) => ({
            key: codeValue.key,
            title: codeValue.desc,
            description: codeValue.desc,
        }));
    }, [projectAllMenuList]);

    const targetKeys = useMemo(() => {
        return projectMenuList.map(({ codeValue }: any) => codeValue.key);
    }, [projectMenuList]);

    const selectKeySplice = useCallback((keys) => {
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === undefined || keys[i] === '') {
                keys.splice(i, 1);
                i--;
            }
        }
        return keys;
    }, []);

    const addMenu = useCallback(
        async (selectedMenu) => {
            if (!selectedMenu || !projectAllMenuList) return;
            createProjectMenu({
                projectId,
                codeType: ECustomCodeType.PROJECT_MENU,
                desc: selectedMenu.desc,
                codeValue: selectedMenu.codeValue,
            });
        },
        [createProjectMenu, projectAllMenuList, projectId],
    );

    const deleteMenu = useCallback(
        async (selectedMenu) => {
            if (!selectedMenu || !projectAllMenuList) return;
            deleteProjectMenu({
                projectId,
                id: selectedMenu.id,
            });
        },
        [deleteProjectMenu, projectAllMenuList, projectId],
    );

    return (
        <section css={transfer}>
            <Transfer
                listStyle={{
                    height: 350,
                    width: '100%',
                }}
                showSelectAll={false}
                operations={['추가', '삭제']}
                dataSource={dataSource}
                targetKeys={targetKeys}
                render={(item) => item.title}
                onChange={(targetKeys, direction, moveKeys) => {
                    const moveKey = moveKeys[0];
                    if (direction === 'right') {
                        const selectedMenu = projectAllMenuList.filter(
                            ({ codeValue }: any) => moveKey === codeValue.key,
                        );
                        addMenu(selectedMenu?.[0]);
                    } else {
                        const selectedMenu = projectMenuList.filter(({ codeValue }: any) => moveKey === codeValue.key);
                        deleteMenu(selectedMenu?.[0]);
                    }
                }}
                onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
                    if (selectKeySplice(sourceSelectedKeys).length === 2) alert('메뉴는 한 개씩 추가 가능합니다.');
                    if (selectKeySplice(targetSelectedKeys).length === 2) alert('메뉴는 한 개씩 삭제 가능합니다.');
                    setSourceSelectedKey(selectKeySplice(sourceSelectedKeys)[0]);
                    setTargetSelectedKey(selectKeySplice(targetSelectedKeys)[0]);
                }}
                selectedKeys={
                    sourceSelectedKey === undefined ? [targetSelectedKey] : [sourceSelectedKey, targetSelectedKey]
                }
            />
        </section>
    );
}

const transfer = css`
    .ant-transfer-list-header {
        display: none;
    }
    h1 {
        font-size: 18px;
        margin: 30px 0 10px;
    }
`;
