import { Form, Input, Button, Select, Radio, Descriptions, Divider, Tag, Tooltip } from 'antd';
import { ModalWrapper } from 'components';
import { useRef, useState, useCallback, useEffect } from 'react';
import AccountDropDown from '../role/AccountDropDown';
import classNames from 'classnames/bind';
import styles from './ProjectModal.module.scss';
import EnterIgnoreForm from 'components/utils/EnterIgnoreForm';
import { ProjectCreateRequest, ProjectDetailDto, ProjectUpdateRequest } from '@gamebase-web-ops/project';
import useProjectDetail from 'hooks/project/useProjectDetail';
import { useFormRules, useAccounts } from 'hooks';
import useGameManage from 'hooks/game/useGameManage';
import { EProjectEnvironment } from '@gamebase-web-ops/project';
import ProjectMenuEdit from './ProjectMenuEdit';
import { AccountBasicDto } from '@gamebase-web-ops/account';
import { notificationPush } from 'utils/notificationUtils';
import { css } from '@emotion/react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { BUTTON_TYPE } from './ProjectList';

const cx = classNames.bind(styles);

interface IProp extends ModalWrapperProps {
    onSubmit?: (arg: ProjectCreateRequest | ProjectUpdateRequest) => void;
    isProjectMenuList: boolean;
    projectValues: ProjectDetailDto | null;
    mode: BUTTON_TYPE;
}

const { Search } = Input;

const langTooltip = (
    <div>
        ko - 한국어
        <br />
        kr - 한국어(국가) <br />
        en - 영어 <br />
        ja - 일본어 <br />
        jp - 일본어(국가)
        <br /> tw - 중국어 번체 ( Simplified Traditional - TC )/ / Chinese Traditional <br />
        zh - 중국어 간체 ( Simplified Chinese - SC )<br /> cn -중국어(국가)
        <br /> fr - 프랑스어 <br />
        de - 독일어 <br />
        id - 인도네시아어 <br />
        it - 이탈리아어
        <br /> pl - 폴란드어
        <br /> pt - 포르투칼어
        <br /> ru - 러시아어
        <br /> es - 스페인어 <br />
        tr - 튀르키예어 (터키어)
        <br /> vi - 베트남 <br />
        th - 태국어
    </div>
);
const ProjectModal: React.FC<IProp> = ({
    visible,
    onCancel,
    onSubmit,
    title,
    projectValues,
    isProjectMenuList,
    mode,
}) => {
    const isProd = process.env.REACT_APP_ENV === 'production';
    const {
        accounts: searchResultAccounts,
        requestAccountsByKeyword,
        setSearchAccounts,
        searchAccounts: projectAccounts,
        addAccounts,
        removeAccounts,
    } = useAccounts();

    const searchRef = useRef<any>();
    const langRef = useRef<HTMLDivElement>(null);
    const [languages, setLanguages] = useState<string[]>(projectValues?.languages ?? []);
    const [dropDownVisible, setDropDownVisible] = useState(false);
    const { projectDetail, refetch } = useProjectDetail(projectValues?.id);
    const { allGameList } = useGameManage();
    const { formValidator } = useFormRules();
    const [hasSheetId, setHasSheetId] = useState<boolean | undefined>(
        projectDetail ? (projectDetail?.driveUrl ? true : false) : undefined,
    );

    const [sheetInfo, setSheetInfo] = useState<{ driveUrl: undefined | string; serverUrl: undefined | string }>({
        driveUrl: projectDetail?.driveUrl,
        serverUrl: projectDetail?.serverUrl,
    });

    useEffect(() => {
        if (projectDetail?.managers) {
            setSearchAccounts(projectDetail?.managers);
        }
        setHasSheetId(projectDetail ? !!projectDetail?.driveUrl : undefined);
        setSheetInfo({
            driveUrl: projectDetail?.driveUrl,
            serverUrl: projectDetail?.serverUrl,
        });
    }, [projectDetail, setSearchAccounts]);

    const handleAddLang = () => {
        if (langRef.current) {
            const newLangs = new Set<string>();
            Array.from(langRef.current.childNodes).map((child) => {
                if (child.textContent) {
                    newLangs.add(child.textContent);
                }
            });
            newLangs.size ? setLanguages([...newLangs.values()]) : setLanguages([]);
        }
    };

    const onSearch = useCallback(
        (keyword: string) => {
            setDropDownVisible(true);
            requestAccountsByKeyword(keyword);
        },
        [requestAccountsByKeyword],
    );
    const onAccountItemClick = useCallback(
        (param: AccountBasicDto) => {
            setDropDownVisible(false);
            addAccounts(param);
        },
        [addAccounts],
    );

    const onFinish = useCallback(
        async (arg) => {
            if (window.confirm('정말로 제출 하시겠습니까?')) {
                if (onSubmit) {
                    if (projectAccounts.length === 0 && projectValues) {
                        return notificationPush({ status: 'error', message: '담당자를 한 명 이상 추가해주세요.' });
                    }
                    const globalId = parseInt(arg.globalId, 10);
                    const name = `${allGameList?.find((gameInfo) => gameInfo.gameId === globalId)?.gameName}(${
                        arg.environment
                    })`;
                    const submitData = {
                        ...arg,
                        languages,
                        serverUrl: sheetInfo.serverUrl ?? '',
                        driveUrl: sheetInfo.driveUrl ?? '',
                        name,
                        globalId,
                        id: projectValues?.id,
                        managerIds: projectAccounts.map(({ id }) => id),
                    };
                    await onSubmit(submitData);
                    refetch();
                }
            }
        },
        [onSubmit, projectAccounts, projectValues, allGameList, languages, sheetInfo, refetch],
    );
    const [form] = Form.useForm();

    const resetField = () => {
        setLanguages([]);
        setSheetInfo({ driveUrl: undefined, serverUrl: undefined });
    };
    if (projectValues && !projectDetail) return null;

    return (
        <ModalWrapper title={title} onCancel={onCancel} visible={visible} width={'750px'}>
            <section className={projectDetail && cx('wrapper')}>
                <EnterIgnoreForm
                    form={form}
                    enabledenter
                    className={projectDetail && cx('formWrapper')}
                    onFinish={onFinish}
                    labelCol={{ span: 3 }}
                >
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label="게임명">
                            <Form.Item
                                name="globalId"
                                rules={formValidator.getRules('create_project_game_id')}
                                initialValue={projectValues?.globalId}
                            >
                                <Select
                                    disabled={isProjectMenuList || mode !== BUTTON_TYPE.CREATE}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    optionFilterProp="children"
                                    data-test_id="gameId"
                                >
                                    {allGameList?.map((it) => (
                                        <Select.Option
                                            key={it.id}
                                            value={it.gameId}
                                            data-test_id={`gameId_${it.gameId}`}
                                        >
                                            {it.gameName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="환경">
                            <Form.Item
                                name="environment"
                                rules={formValidator.getRules('create_project_env')}
                                initialValue={projectValues?.environment}
                            >
                                <Radio.Group disabled={isProjectMenuList || mode !== BUTTON_TYPE.CREATE}>
                                    {isProd ? (
                                        <Radio value={EProjectEnvironment.PRODUCTION}>
                                            {EProjectEnvironment.PRODUCTION.toLocaleUpperCase()}
                                        </Radio>
                                    ) : (
                                        <>
                                            <Radio value={EProjectEnvironment.QA}>
                                                {EProjectEnvironment.QA.toLocaleUpperCase()}
                                            </Radio>
                                            <Radio value={EProjectEnvironment.DEVELOPMENT}>
                                                {EProjectEnvironment.DEVELOPMENT.toLocaleUpperCase()}
                                            </Radio>
                                        </>
                                    )}
                                </Radio.Group>
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="시트 ID 사용">
                            <Form.Item
                                name="hasSheetId"
                                rules={formValidator.getRules('sheet_id')}
                                initialValue={!!projectDetail?.driveUrl}
                            >
                                <Radio.Group
                                    value={hasSheetId}
                                    onChange={(e) => {
                                        setHasSheetId(e.target.value);
                                    }}
                                    disabled={isProjectMenuList}
                                >
                                    <Radio value={true}>시트 ID 사용</Radio>
                                    <Radio value={false}>시트 ID 사용 X (A+B)</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Descriptions.Item>

                        {hasSheetId !== undefined &&
                            (hasSheetId ? (
                                <>
                                    <Descriptions.Item label="시트 ID">
                                        <Form.Item
                                            name="driveUrl"
                                            rules={[
                                                {
                                                    required: !isProjectMenuList,
                                                    message: '시트 아이디를 입력해 주세요.',
                                                },
                                            ]}
                                            initialValue={projectDetail?.driveUrl}
                                        >
                                            <Input
                                                defaultValue={projectDetail?.driveUrl}
                                                disabled={isProjectMenuList}
                                                onChange={(e) => {
                                                    setSheetInfo((prev) => ({ ...prev, driveUrl: e.target.value }));
                                                }}
                                            />
                                        </Form.Item>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="서버 URL">
                                        <Form.Item name="serverUrl">
                                            <Input
                                                placeholder="서버주소가 https로 시작하는지 확인해 주세요."
                                                defaultValue={sheetInfo.serverUrl}
                                                disabled={isProjectMenuList}
                                                onChange={(e) => {
                                                    setSheetInfo((prev) => ({ ...prev, serverUrl: e.target.value }));
                                                }}
                                            />
                                        </Form.Item>
                                    </Descriptions.Item>
                                </>
                            ) : (
                                <>
                                    <Descriptions.Item
                                        label={
                                            <>
                                                <span>언어 추가</span>
                                                <Tooltip
                                                    placement="top"
                                                    title={langTooltip}
                                                    // color="blue"
                                                    color="#000000d9"
                                                    overlayStyle={{
                                                        maxWidth: '520px',
                                                        fontSize: '10px',
                                                        // marginLeft: '-45px',
                                                    }}
                                                    trigger="click"
                                                >
                                                    <InfoCircleOutlined style={{ marginLeft: '10px' }} />
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        {isProjectMenuList ? (
                                            <>
                                                {languages?.map((lang) => (
                                                    <Tag key={lang}>{lang}</Tag>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                <div css={projectLang}>
                                                    <div
                                                        contentEditable
                                                        suppressContentEditableWarning
                                                        css={textArea}
                                                        ref={langRef}
                                                    ></div>
                                                    <Button type="primary" onClick={handleAddLang}>
                                                        언어 추가
                                                    </Button>
                                                </div>
                                                <Divider />
                                                <div css={projectLang}>
                                                    <div css={textArea}>
                                                        {languages?.map((lang) => (
                                                            <Tag
                                                                color="blue"
                                                                key={lang}
                                                                closable
                                                                onClose={() => {
                                                                    setLanguages(() =>
                                                                        languages.filter((curLang) => curLang !== lang),
                                                                    );
                                                                }}
                                                            >
                                                                {lang}
                                                            </Tag>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </Descriptions.Item>
                                </>
                            ))}

                        {projectValues && (
                            <Descriptions.Item label="담당자">
                                <Form.Item name="managers">
                                    {!isProjectMenuList && (
                                        <>
                                            <Search
                                                placeholder="아이디나 별명을 입력해주세요"
                                                onSearch={onSearch}
                                                onFocusCapture={() => setDropDownVisible(false)}
                                                enterKeyHint="search"
                                                enterButton="검색"
                                                ref={searchRef as never}
                                            />
                                            {dropDownVisible && (
                                                <AccountDropDown
                                                    accounts={searchResultAccounts}
                                                    onClick={onAccountItemClick}
                                                />
                                            )}
                                        </>
                                    )}

                                    <div className={cx('userBadgeWrapper')}>
                                        {projectAccounts?.map((account) => (
                                            <Tag
                                                color={isProjectMenuList ? '' : 'blue'}
                                                key={account.id}
                                                closable={!isProjectMenuList}
                                                onClose={() => {
                                                    removeAccounts(account);
                                                }}
                                            >
                                                {account.name}
                                            </Tag>
                                        ))}
                                    </div>
                                </Form.Item>
                            </Descriptions.Item>
                        )}
                        {projectDetail && isProjectMenuList && (
                            <Descriptions.Item label="프로젝트 메뉴">
                                <ProjectMenuEdit project={projectDetail} />
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                    <div css={footer}>
                        <Button onClick={onCancel}>취소</Button>
                        <Button htmlType="submit" type="primary">
                            저장
                        </Button>
                    </div>
                </EnterIgnoreForm>
            </section>
        </ModalWrapper>
    );
};

export default ProjectModal;

const projectLang = css`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const textArea = css`
    border: 1px solid rgb(217, 217, 217);
    width: 75%;
    height: 100px;
    overflow: auto;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    cursor: text;
    :hover {
        border: 1px solid rgb(24, 144, 255);
    }
`;

const footer = css`
    margin-top: 16px;
    text-align: right;
    > button {
        margin-left: 10px;
    }
`;
