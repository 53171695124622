import { Button, Spin } from 'antd';
import { OddFillTable, ProjectModal } from 'components';
import { useModal } from 'hooks';
import { useCallback, useMemo, useState } from 'react';
import { ProjectCreateRequest, ProjectDetailDto, ProjectUpdateRequest } from '@gamebase-web-ops/project';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { css } from '@emotion/react';
import { HeaderDesc } from 'components/utils/Header';
import ProjectDetail from './ProjectDetail';
import useProjectList from 'hooks/admin/useProjectList';
import { COMMON_PROJECT_ID } from 'constants/constants';

interface ProjectListProps {
    isProjectMenuList: boolean;
}

export enum BUTTON_TYPE {
    CREATE,
    DETAIL,
    EDIT,
}

const ProjectList: React.FC<ProjectListProps> = ({ isProjectMenuList }) => {
    const {
        myProjectList,
        isProjectListError,
        isProjectListLoading,
        projectDetail,
        setProjectDetail,
        createProject,
        updateProject,
        deleteProject,
    } = useProjectList();
    const [clickMode, setClickType] = useState<BUTTON_TYPE>(BUTTON_TYPE.CREATE);
    const { visible, openModal, closeModal } = useModal();
    const onCreateProjectSubmit = useCallback(
        async (arg: ProjectCreateRequest | ProjectUpdateRequest) => {
            if (projectDetail) {
                await updateProject(arg as ProjectUpdateRequest);
                closeModal();
                setProjectDetail(null);
                // await withScreenLockCall(putProjectRequest, arg as ProjectUpdateRequest);
            } else {
                await createProject(arg as ProjectCreateRequest);
                closeModal();
                setProjectDetail(null);
                //  await withScreenLockCall(createProjectRequest, arg as ProjectCreateRequest);
            }
            closeModal();
            setProjectDetail(null);
        },
        [projectDetail, closeModal, setProjectDetail, updateProject, createProject],
    );

    const closeProjectModal = useCallback(() => {
        closeModal();
        setProjectDetail(null);
    }, [closeModal, setProjectDetail]);

    const deleteClickListener = useCallback(
        async (id: string) => {
            if (window.confirm('프로젝트를 정말 삭제하시겠습니까?')) {
                await deleteProject({ id, projectId: COMMON_PROJECT_ID });
                // await withScreenLockCall(deleteProjectRequest, id);
            }
        },
        [deleteProject],
    );

    const projectTableColumns = useMemo<ColumnsType<ProjectDetailDto>>(
        () => [
            {
                key: 'name',
                dataIndex: 'name',
                title: '프로젝트명',
            },

            {
                key: 'createdAt',
                dataIndex: 'createdAt',
                title: '생성일',
                render: (createdAt) => {
                    return moment(createdAt).format('YYYY-MM-DD HH:mm:ss');
                },
            },
            {
                key: 'createdBy',
                dataIndex: 'createdBy',
                title: '생성자',
            },
            {
                key: 'updatedAt',
                dataIndex: 'updatedAt',
                title: '수정일',
                render: (createdAt) => {
                    return moment(createdAt).format('YYYY-MM-DD HH:mm:ss');
                },
            },
            {
                key: 'updatedBy',
                dataIndex: 'updatedBy',
                title: '수정자',
            },
            {
                key: 'management',
                dataIndex: 'management',
                title: '관리',
                render(_, record) {
                    return (
                        <div css={managementBtnWrapper}>
                            <Button
                                onClick={async () => {
                                    setClickType(BUTTON_TYPE.DETAIL);
                                    await setProjectDetail(record);
                                    openModal();
                                }}
                            >
                                상세
                            </Button>
                            <Button
                                onClick={() => {
                                    setClickType(BUTTON_TYPE.EDIT);
                                    setProjectDetail(record);
                                    openModal();
                                }}
                            >
                                수정
                            </Button>
                            <Button
                                type="primary"
                                danger
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    deleteClickListener(record.id);
                                }}
                            >
                                삭제
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [deleteClickListener, openModal, setProjectDetail],
    );
    return (
        <div>
            {visible &&
                (clickMode === BUTTON_TYPE.DETAIL ? (
                    <ProjectDetail
                        visible={visible}
                        title="프로젝트 상세"
                        onCancel={closeProjectModal}
                        projectValues={projectDetail}
                    />
                ) : (
                    <ProjectModal
                        isProjectMenuList={isProjectMenuList}
                        visible={visible}
                        onCancel={closeProjectModal}
                        title={projectDetail ? '프로젝트 수정' : '프로젝트 생성'}
                        onSubmit={onCreateProjectSubmit}
                        projectValues={projectDetail}
                        mode={clickMode}
                    />
                ))}

            {!isProjectMenuList && (
                <div css={buttonWrapper}>
                    <HeaderDesc desc="프로젝트를 생성하고 콘텐츠 권한을 설정할 수 있습니다." />
                    <Button
                        onClick={() => {
                            setClickType(BUTTON_TYPE.CREATE);
                            openModal();
                        }}
                        type="primary"
                    >
                        생성
                    </Button>
                </div>
            )}
            <Spin spinning={isProjectListLoading} tip="로딩중..">
                {isProjectListError ? (
                    <div>권한이 없습니다.</div>
                ) : (
                    <OddFillTable
                        pagination={{ position: ['bottomCenter'] }}
                        dataSource={myProjectList?.map((project: any) => ({ ...project, key: project.id }))}
                        columns={projectTableColumns}
                    />
                )}
            </Spin>
        </div>
    );
};

const buttonWrapper = css`
    display: flex;
    justify-content: space-between;
    margin: 10px 0 22px;
`;

const managementBtnWrapper = css`
    display: flex;
    > button {
        margin-left: 10px;
    }
`;

export default ProjectList;
