import { css } from '@emotion/react';
import { Form, Menu } from 'antd';
import useMenuItems from 'hooks/antd/useMenuItems';
import { ENoticePlatform, ENoticePlatform_TEXT } from 'constants/constants';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import { useState } from 'react';
interface ISecondStep {
    stepData: any;
}
function SecondStep({ stepData }: ISecondStep) {
    const { getItem } = useMenuItems();
    const { description, messages, startTime, endTime, defaultLanguage, titles, platform, versions } = stepData;
    const languageKeys = Object.keys(messages);
    const menuItems = languageKeys.map((language) => {
        return getItem(<div key={language}>{language}</div>, language);
    });
    const [selectedLanguage, setSelectedLanguage] = useState<string>(defaultLanguage);
    const { messageFormatting } = useFormatMessage();
    return (
        <Form>
            <div css={noticePreview}>
                <Form.Item labelCol={{ span: 2, offset: 0 }} label={messageFormatting('TITLE')}>
                    {description}
                </Form.Item>
                <Form.Item labelCol={{ span: 2, offset: 0 }} label={messageFormatting('APP_VERSION')}>
                    {versions ? versions.join(' | ') : ''}
                </Form.Item>
                <Form.Item labelCol={{ span: 2, offset: 0 }} label={messageFormatting('EXPOSURE_PERIOD')}>
                    {moment(startTime).format('YYYY-MM-DD HH:mm:ss')} ~ {moment(endTime).format('YYYY-MM-DD HH:mm:ss')}
                </Form.Item>
                <Form.Item labelCol={{ span: 2, offset: 0 }} label={messageFormatting('NOTICE_FIRST_LANGUAGE')}>
                    {defaultLanguage}
                </Form.Item>
                <Form.Item labelCol={{ span: 2, offset: 0 }} label={messageFormatting('LANGUAGE')}>
                    <Menu
                        mode="horizontal"
                        selectedKeys={[selectedLanguage]}
                        onClick={(e) => setSelectedLanguage(e.key)}
                        items={menuItems}
                    ></Menu>
                </Form.Item>
                {titles && (
                    <Form.Item label={messageFormatting('TITLE')} labelCol={{ span: 2, offset: 0 }}>
                        <pre css={preStyle}>{titles[selectedLanguage]}</pre>
                    </Form.Item>
                )}
                <Form.Item label={messageFormatting('CONTENT')} labelCol={{ span: 2, offset: 0 }}>
                    <pre css={preStyle}>{messages[selectedLanguage]}</pre>
                </Form.Item>
                {platform !== undefined && (
                    <Form.Item label="platform">
                        <div>{ENoticePlatform_TEXT[platform as ENoticePlatform]}</div>
                    </Form.Item>
                )}
            </div>
            <div>{messageFormatting('NOTICE_CONFIRM')}</div>
        </Form>
    );
}

export default SecondStep;

const noticePreview = css`
    border: 1px solid #ddd;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 12px;
`;

const preStyle = css`
    width: 100%;
    padding-left: 1%;
    white-space: pre-wrap;
`;
