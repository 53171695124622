import { useFormatMessage, useProjectDetail, useQueries } from 'hooks';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CATEGORIES } from 'constants/constants';
import { MenuIcon } from './MenuIcon';
import { EnvColor } from 'styles/common';

export default function MenuItem({ mainMenu, subMenu }: ISideMenu) {
    const { messageFormatting } = useFormatMessage();
    const { pathname } = useLocation();
    const { projectId } = useQueries();
    const { projectDetail } = useProjectDetail(projectId);

    const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({});
    useEffect(() => {
        const MAIN_MENU = pathname.split('/')[2];
        if (MAIN_MENU === 'dashboard') {
            setIsOpen({ [CATEGORIES[0]]: true });
            return;
        }
        setIsOpen({
            [MAIN_MENU]: true,
        });
    }, [pathname]);

    const toggleOpen = (main: string) => {
        setIsOpen({
            ...isOpen,
            [main]: !isOpen[main],
        });
    };

    return (
        <ul css={mainMenuWrap(isOpen[mainMenu])}>
            <li
                css={mainMenuItem(isOpen[mainMenu], projectDetail && projectDetail.environment)}
                onClick={() => {
                    toggleOpen(mainMenu);
                }}
            >
                {MenuIcon[mainMenu]}
                {messageFormatting(mainMenu)}
                <IoIosArrowDown css={arrowIcon(isOpen[mainMenu])} size="20px" />
            </li>
            <li css={subMenuWrap(isOpen[mainMenu])}>
                {subMenu.length !== 0 ? (
                    subMenu.map(({ key, desc, link }) => {
                        if (key === 'indicator') {
                            return (
                                <a
                                    css={subMenuItem(
                                        pathname.includes(key),
                                        projectDetail && projectDetail.environment,
                                    )}
                                    key={key}
                                    href="https://redash.111percent.net"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {desc}
                                </a>
                            );
                        }
                        return (
                            <Link
                                css={subMenuItem(pathname.includes(key), projectDetail && projectDetail.environment)}
                                key={key}
                                to={link}
                            >
                                {desc}
                            </Link>
                        );
                    })
                ) : (
                    <Link
                        css={subMenuItem(false, projectDetail && projectDetail.environment)}
                        to={`/common/siteManagement/contentsMenu?projectId=${projectId}`}
                    >
                        메뉴를 추가해주세요.
                    </Link>
                )}
            </li>
        </ul>
    );
}

const arrowIcon = (isOpen: boolean) => css`
    transition: all 0.6s ease;
    ${isOpen &&
    css`
        transform: rotate(180deg);
    `}
`;

const subMenuItem = (click: boolean, env?: string) => css`
    color: #a4a5be;
    display: block;
    padding: 8px 0;
    font-size: 15px;
    &:hover {
        color: ${EnvColor[env || 'common']};
    }
    ${click &&
    css`
        color: ${EnvColor[env || 'common']};
    `}
`;

const subMenuWrap = (isOpen: boolean) => css`
    padding: 0px 20px;
    visibility: hidden;
    max-height: 0px;
    transition: max-height 0.6s, visibility 0.7s ease;
    ${isOpen &&
    css`
        visibility: visible;
        height: auto;
        max-height: 400px;
        transition: max-height 0.6s, visibility 0.7s ease;
    `};
`;

const mainMenuItem = (isOpen: boolean, env?: string) => css`
    color: #545454;
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${isOpen
        ? css`
              background-color: ${EnvColor[env || 'common']};
              color: #ffffff;
              transition: background-color 0.3s ease;
          `
        : css`
              transition: background-color 0.3s ease;
              border-bottom: 2px solid #f1f1f1;
              &:hover {
                  color: ${EnvColor[env || 'common']}; //menu hover
              }
          `}
`;

const mainMenuWrap = (isOpen: boolean) => css`
    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #ffffff;
    transition: background-color 0.7s ease;
    ${isOpen &&
    css`
        border: 1px solid #ededed;
        background-color: #fcfcfc;
        margin-bottom: 20px;
    `}
`;
