import { EBanRequestState } from '@gamebase-ops/interfaces/consts';
import { Auth } from '@gamebase-web/common';
import { BlockUserListQueryDto } from 'dto/blockUser.dto';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { BlockUserRepository } from 'repository';
import { notificationPush } from 'utils/notificationUtils';

export default function useBlockUserModel({
    projectId,
    limit = 1,
    defaultListQuery,
}: {
    projectId: string;
    limit: number;
    banState?: EBanRequestState;
    defaultListQuery?: Omit<BlockUserListQueryDto, 'projectId'>;
}) {
    const [listQuery, setListQuery] = useState<Omit<BlockUserListQueryDto, 'projectId'> | undefined>(defaultListQuery);

    const queries = useQuery(['blockUserData', projectId, listQuery], () =>
        listQuery
            ? BlockUserRepository.list({
                  query: {
                      page: listQuery.page || 1,
                      projectId,
                      limit,
                      accountId: listQuery.accountId,
                      guid: listQuery.guid,
                      state: listQuery.state,
                  },
              })
            : undefined,
    );

    const reBan = useMutation(
        async () => {
            await BlockUserRepository.reBan(new Auth({ projectId }));
        },
        {
            onSuccess: () => {
                notificationPush({
                    status: 'success',
                    message: '요청이 완료 되었습니다.',
                });
            },
            onError: () => {
                notificationPush({
                    status: 'error',
                    message: `요청에 실패했습니다.`,
                });
            },
        },
    );
    const { mutateAsync: reBanUser, isLoading: isReBan } = reBan;

    return {
        ...queries,
        setListQuery,
        listQuery,
        reBanUser,
        isReBan,
    };
}
