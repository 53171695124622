import { MENU_LIST, CATEGORIES } from 'constants/constants';
import { useMemo } from 'react';
import useProjectMenu from 'hooks/admin/useProjectMenu';

export default function useProjectCategory(projectId: string) {
    const { projectMenuList } = useProjectMenu(projectId);
    const categories = useMemo(() => {
        const menuKeyDesc: { [key: string]: string } = projectMenuList.reduce((accumulator, value) => {
            return { ...accumulator, [value.codeValue.key]: value.codeValue.desc };
        }, {});
        const menuList = CATEGORIES.map((category) => {
            return {
                mainMenu: category,
                subMenu: MENU_LIST[category].reduce(
                    (acc: { key: string; desc: string; link: string }[], cur: string) => {
                        if (Object.keys(menuKeyDesc).includes(cur)) {
                            acc.push({
                                key: cur,
                                desc: menuKeyDesc[cur],
                                link: `/project/${category}/${cur}${projectId ? `?projectId=${projectId}` : ''}`,
                            });
                        }
                        return acc;
                    },
                    [],
                ),
            };
        });
        return menuList;
    }, [projectMenuList, projectId]);

    return { categories, projectMenuList };
}
