import 'reflect-metadata';
import { Switch, Route } from 'react-router-dom';
import { WithIntlProvider } from 'provider';
import { LoginPage, ProjectListPage, Home } from 'pages';
import { useAccount, useError } from 'hooks';
import { Suspense, useEffect } from 'react';
import ProjectRoute from 'routes/ProjectRoute';
import CommonRoute from 'routes/CommonRoute';
import { MainLayout, ScreenLockPending } from 'components';
import MyPageRoute from 'routes/MyPageRoute';

const MustLoginUsePage = () => {
    return (
        <Suspense fallback={<div>로딩중</div>}>
            <ScreenLockPending />
            <Switch>
                <Route path="/login" component={LoginPage} />
                <MainLayout>
                    <Route path="/" exact component={ProjectListPage} />
                    <Route path="/common" component={CommonRoute} />
                    <Route path="/project" component={ProjectRoute} />
                    <Route path="/myPage" component={MyPageRoute} />
                    <Route path="/dashboard" render={() => <Home />} />
                </MainLayout>
            </Switch>
        </Suspense>
    );
};

function App(): JSX.Element {
    const { getMyDataRequest, account } = useAccount();

    const { errorHandle } = useError();

    useEffect(() => {
        getMyDataRequest();
    }, [getMyDataRequest]);

    useEffect(() => {
        window.addEventListener('unhandledrejection', errorHandle);
        return () => {
            window.removeEventListener('unhandledrejection', errorHandle);
        };
    }, [errorHandle]);

    return !account?.account?.id ? (
        <Switch>
            <Route path="/login" component={LoginPage} />
        </Switch>
    ) : (
        <MustLoginUsePage />
    );
}

export default WithIntlProvider(App);
