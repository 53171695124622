import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './ManageMentMenu.module.scss';
import { useAuth } from 'hooks';
import { PageAuthViewController } from 'provider';
import { COMMON_PROJECT_ID } from 'constants/constants';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { EServiceAuthLevel, EServiceAuthName } from '@gamebase-web-ops/authorization/lib/v2';
import { MdLogout } from 'react-icons/md';
import { css } from '@emotion/react';

interface IProp extends IAuthPageProps {
    isAdmin: boolean;
    username: string;
}

const cx = classNames.bind(styles);

const ManageMentMenu: React.FC<IProp> = ({ auths, isAdmin, username }) => {
    const { logoutRequest } = useAuth();

    return (
        <section className={cx('infoWrapper')}>
            <div css={mainMenuWrap}>
                <Link to="/">게임 선택</Link>
                <Link to="/common/commonManagement/game">통합 관리</Link>
            </div>
            <span css={myPage}>
                <Tooltip title="내정보">
                    <Link to="/myPage" className={cx('settingOption')}>
                        <FormattedMessage
                            id="HEADER_HELLO"
                            values={{
                                name: username,
                            }}
                        />
                    </Link>
                </Tooltip>
            </span>
            <Tooltip title="로그아웃">
                <div key="logout" onClick={logoutRequest} css={logout} className={cx('settingOption')}>
                    <FormattedMessage id="LOGOUT" />
                    <MdLogout />
                </div>
            </Tooltip>
        </section>
    );
};

const myPage = css`
    margin-left: 15px;
`;

const logout = css`
    display: flex;
    align-items: center;
    color: #6e6e6e;
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 20px;
    transition: background-color 0.2s ease;
    cursor: pointer;
    svg {
        margin-left: 4px;
        font-size: 18px;
    }
    &:hover {
        background-color: #ffffff37;
    }
`;

const mainMenuWrap = css`
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    a {
        color: #5e6278;
        border-radius: 15px;
        padding: 10px 15px;
        &:hover {
            color: #1890ff;
            background-color: #ffffff37;
            transition: background-color color 0.6s ease;
        }
    }
`;

const ManageMentInject = (props: IProp) => {
    return (
        <PageAuthViewController
            service={EServiceAuthName.ACCOUNT}
            level={EServiceAuthLevel.READ}
            projectId={COMMON_PROJECT_ID}
        >
            <PageAuthViewController
                service={EServiceAuthName.ROLE}
                level={EServiceAuthLevel.READ}
                projectId={COMMON_PROJECT_ID}
            >
                <PageAuthViewController
                    service={EServiceAuthName.GAME}
                    level={EServiceAuthLevel.READ}
                    projectId={COMMON_PROJECT_ID}
                >
                    <PageAuthViewController
                        service={EServiceAuthName.CROSS_PROMOTION}
                        projectId={COMMON_PROJECT_ID}
                        level={EServiceAuthLevel.READ}
                    >
                        <ManageMentMenu {...props} />
                    </PageAuthViewController>
                </PageAuthViewController>
            </PageAuthViewController>
        </PageAuthViewController>
    );
};

export default memo(ManageMentMenu);
