import { useCallback } from 'react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { googleLoginClientId } from 'constants/constants';
import { css } from '@emotion/react';

interface IProps {
    onSuccessGoogleLogin?: (payload: GoogleLoginPayload) => void;
}

const GoogleLoginButton: React.FC<IProps> = ({ onSuccessGoogleLogin }) => {
    const onSuccess = useCallback(
        (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
            const {
                accessToken,
                profileObj: { email },
            } = response as GoogleLoginResponse;
            if (onSuccessGoogleLogin && accessToken) {
                onSuccessGoogleLogin({ accessToken, email });
            }
        },
        [onSuccessGoogleLogin],
    );

    return (
        <GoogleLogin
            css={loginButton}
            clientId={googleLoginClientId}
            onSuccess={onSuccess}
            buttonText="Sign In 111% Email"
        />
    );
};
const loginButton = css`
    width: 100%;
    color: #000000;
    text-align: center;

    box-shadow: rgb(0 0 0 / 14%) 0px 3px 3px 0px, rgb(0 0 0 / 14%) 0px 0px 2px 0px !important;
    :hover {
        box-shadow: rgb(0 0 0 / 14%) 0px 4px 4px 0px, rgb(0 0 0 / 14%) 0px 0px 2px 0px !important;
        transition-duration: 0.4s;
        span {
            opacity: 0.8;
            transition-duration: 0.2s;
        }
    }
    > div {
        margin-right: 0 !important;
    }
    span {
        width: 100%;
        color: #000000;
    }
`;

export default GoogleLoginButton;
